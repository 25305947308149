<template>
	<div class=" flex flex-col h-full overflow-hidden">
		<div class="p-6 md:px-12">
			<form 
				@submit.prevent="onSearch()"
				class="relative">
				
				<input
					type="text" 
					v-model="searchTerm" 
				/>
				
				<div 
					class="absolute right-0 top-0 h-full flex items-center px-4" 
					@click.stop="onSearch()">
					
					<i class="fas fa-search" />
				</div>
			</form>
		</div>
		
		<div class="flex-grow overflow-auto p-6 md:px-12"> 
			<div 
				v-if="results.length"
				class="flex-grow grid grid-cols-2 gap-4 sm:grid-cols-6"
				:class="gifSelected ? 'opacity-25' : ''">
				
				<div 
					v-for="gif in results"
					:key="gif.id"
					@click="gifSelected = gif">
					
					<img 
						:src="gif.images.original.url" 
						class="
							object-cover w-full h-full
							bg-black
						"
					/>
				</div>
			</div>
				
			<button 
				@click.prevent="onLoadMore()"
				class="text-center p-6 w-full">
				
				Last flere
			</button>
		</div>
		
        <Modal 
            v-if="gifSelected"
            @close="gifSelected = null"
            insideClasses="bg-white">
            
			<img 
				:src="gifSelected.images.original.url" 
				class="w-200"
			/>
			
            <footer slot="footer">
                <div class="space-x-4 pt-6 flex w-full">
                    <a 
                        class="button flex-1" 
                        @click.prevent="gifSelected = null">
                        
                        Lukk
                    </a>
                    <button 
                        type="submit"
                        class="button submit flex-1"
						@click.prevent="onSelect(gifSelected)">
                        
                        Velg
                    </button>
                </div>
            </footer>
        </Modal>
        
	</div>
</template>

<script>
	export default {
		data(){
			return {
				searchTerm: 'celebrate',
				results: [],
				offset: 0,
				limit: 30,
				apiKey: 'RAs4ZVBjqSPSiokBBovPIVevdVFAcsfB', // RAs4ZVBjqSPSiokBBovPIVevdVFAcsfB = is a beta key. Remember to upgrade: https://developers.giphy.com/dashboard/
				searchEndPoint: 'https://api.giphy.com/v1/gifs/search?',
				gifSelected: null,
			}
		},
		
		methods: {
			async onSearch(){
				try {
					this.results = [];
					this.offset = 0;
					const url = `${this.searchEndPoint}&api_key=${this.apiKey}&q=${this.searchTerm}&limit=${this.limit}&offset=${this.offset}`;
					const response = await this.$axios.get(url);
					this.results = response.data.data;
				} 
				catch (error) {
					console.error(error);
				}
			},
			
			async onLoadMore(){
				this.offset = this.offset + this.limit;
				
				const url = `${this.searchEndPoint}&api_key=${this.apiKey}&q=${this.searchTerm}&limit=${this.limit}&offset=${this.offset}`;
				const response = await this.$axios.get(url);
				this.results = [
					...this.results,
					...response.data.data,
				];
			},
			
			onSelect( gif ) {
				this.$emit('onSelect', gif);
			},
		},
		
		mounted(){
			this.onSearch();
		}
	}
</script>